.std-meta {
    margin-bottom: 15px;
}

.std-meta-key {
    font-size: 14px;
    color: white;
}
.std-meta-value {
    font-size: 12px;
}
.std-meta-list {
    ul {
        font-size: 12px;
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
.std-meta-socials {
    padding-top: 3px;
    .btn {
        min-width: 36px;
        margin-bottom: 5px;
        text-align: center;
    }
}
