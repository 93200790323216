.sticky-video {
    position: sticky;
    top: 45px;
    z-index: 999;

    &.locked {
        position: relative;
        top: auto;
        z-index: 0;
    }

    .btn-unstick {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 5px;
        right: 5px;
        left: auto;
        opacity: .5;
        font-size: 10px;
    }
}

@include media-breakpoint-up(sm) {
    .sticky-video {
        position: relative;
        top: auto;
        z-index: 4;

        &.locked {
            .sticky-video-float {
                position: relative;
                z-index: 0;
                bottom: auto;
                right: auto;
            }
        }
    }

    .sticky-video-float {
        position: fixed;
        bottom: 10px;
        right: 10px;
        width: 400px;
    }
}

// @include media-breakpoint-up(xl) {
//     .sticky-video-item-float {
//         position: fixed;
//         bottom: 10px;
//         right: 10px;
//         width: 400px;
//         z-index: 9999;
//         top: auto;
//         left: auto;
//     }
// }
