.form-list-item {
    position: relative;
}

.form-list-item_meta {
    font-size: 11px;

    > span {
        margin-right: 20px;

        i {
            margin-right: 5px;
        }
    }
}

.form-list-item_action {
    position: absolute;
    top: 5px;
    right: 5px;

    a {
        display: inline-block;
        padding: 5px;
        font-size: 11px;
        color: inherit;
        opacity: 1;
        transition: opacity .2s ease;

        &:hover {
            opacity: .5;
        }
    }
}
