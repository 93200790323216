#page-profile-login_container {
    background-color: #222;
    max-width: 360px;
    margin: 0 auto;
    padding: 0 40px 40px;
}

#page-profile-register_tab {
    white-space: nowrap;
    overflow: auto;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 0;

        li {
            display: inline-block;
            text-align: center;
            padding: 0 30px;
            position: relative;
            font-size: $font-size-base;

            &:after {
                content: '';
                height: 3px;
                width: 100%;
                background-color: #222;
                top: 13px;
                left: 0;
                position: absolute;
                z-index: 1;
            }

            &:first-child {
                &:after {
                    width: 50%;
                    left: 50%;
                }
            }

            &:last-child {
                &:after {
                    width: 50%;
                }
            }

            .icon {
                display: inline-block;
                width: 30px;
                height: 30px;
                border-radius: 1000px;
                border: 3px solid #222;
                text-align: center;
                margin-bottom: 10px;
                background-color: #333;
                z-index: 2;
                position: relative;

                &.past,
                &.active {
                    background-color: #222;
                }
            }

            .text {
                display: block;
                font-size: 13px;
            }
        }
    }
}

#page-profile-register_form-small {
    max-width: 360px;
    margin: 0 auto;
}
