.post-embed {
    position: absolute;
    top: 8px;
    right: 15px;
    display: inline-block;
    font-size: 20px;
}

// -- post-list

.post-list_item {
    position: relative;
    display: block;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #454545;
    color: #999;
    transition: color .2s ease;

    &:last-child {
        border-bottom: 0 none;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    &:hover {
        color: white;

        img {
            opacity: .7;
        }
    }

    img {
        opacity: 1;
        transition: opacity .2s ease;
    }

    .post-embed {
        top: 0;
        left: 5px;
        right: auto;
        color: white;
    }
}

.post-list_item-title {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.post-list_item-meta {
    font-size: 11px;
    font-style: italic;
}

// --- post-thumb

.post-thumb {
    display: block;
    position: relative;
    color: white;

    img {
        width: 100%;
        height: auto;
        opacity: 1;
        transition: opacity .2s ease;
    }

    &:hover {
        color: white;

        img {
            opacity: .5;
        }
    }
}

@include media-breakpoint-up(sm) {
    .post-thumb {
        &.big {
            .post-thumb_info {
                h3 {
                    font-size: 30px;
                }
            }
        }
    }
}

.post-thumb_info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 20px 25px;
    background: linear-gradient(to bottom,rgba(17,17,17,0) 0,rgba(17,17,17,.1) 10%,rgba(17,17,17,.95) 100%);
    h3 {
        font-size: 15px;
        padding: 0;
        margin: 5px 0 0;
        text-shadow: 0 0 4px #222;

        line-clamp: 2;
        -webkit-line-clamp: 2;
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }
}

.post-thumb_meta {
    font-size: 11px;
    text-shadow: 0 0 4px #222;
}

.post-media-list {
    display: block;
    position: relative;
    color: inherit;
    transition: color .2s ease;

    .cover {
        width: 100%;
        height: auto;
        opacity: 1;
        transition: opacity .2s ease;
    }

    .title {
        font-size: 18px;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }

    .meta {
        font-size: 11px;
    }

    &:hover {
        color: white;
        .cover {
            opacity: .5;
        }
    }

    .post-embed {
        position: absolute;
        top: 25px;
        left: 15px;
    }

    .preface {
        font-size: 12px;
        margin-top: 10px;

        line-clamp: 2;
        -webkit-line-clamp: 2;
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }
}
