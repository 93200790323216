#sidebar {
    min-height: 100%;
}
#sidebar_container_friend {
    margin-top: 30px;
}

.sidebar_social_item {
    display: block;
    position: relative;
    padding-left: 50px;
    font-size: 12px;
    font-weight: 700;
    background-color: #222;
    height: 40px;
    opacity: 1;
    transition: opacity .2s ease;

    &:hover {
        opacity: .5;
    }

    .icon {
        position: absolute;
        top: 0;
        left: 0;
        background-color: var(--color);
        color: white;
        width: 40px;
        height: 40px;
        text-align: center;
        font-size: 14px;
        padding-top: 9px;

        &:after {
            content: '';
            border: 4px solid var(--color);
            border-right-color: transparent;
            border-bottom-color: transparent;
            border-top-color: transparent;
            position: absolute;
            top: 16px;
            left: 40px;
        }
    }

    .action {
        color: white;
        padding-top: 3px;
    }

    .label {
        color: #999;
        line-height: 13px;
    }
}

#sidebar_tab-post {
    padding-top: 30px;
}
