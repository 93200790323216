.music-album-thumb {
    display: block;
    color: inherit;

    .cover {
        position: relative;

        img {
            opacity: 1;
            transition: opacity .2s ease;
            width: 100%;
            height: auto;
        }

        i.play {
            font-size: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -25px;
            margin-left: -25px;
            color: white;
            opacity: 0;
            transition: opacity;
        }

        i.icon {
            color: white;
            position: absolute;
            top: 10px;
            left: 15px;
            font-size: 17px;
        }
    }

    .name {
        line-height: 17px;
    }

    .author {
        font-size: 13px;
    }

    .meta {
        font-size: 11px;

        i {
            margin-left: 10px;
            margin-right: 2px;
        }
    }
}
a.music-album-thumb {
    &:hover {
        color: inherit;

        .cover {
            img {
                opacity: .5;
            }
            i {
                opacity: 1;
            }
        }
    }
}


.music-album-thumb_name {
    font-size: 16px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 14px;
}
.music-album-thumb_author {
    font-size: 12px;
}

.music-album-thumb_meta {
    font-size: 11px;
    line-height: 8px;

    i {
        margin-right: 2px;
        margin-left: 10px;
    }
}

.page-music_list_item {
    display: block;
    color: inherit;

    &:hover {
        color: inherit;

        .cover {
            img {
                opacity: .5;
            }
        }
    }

    .cover {
        position: relative;

        img {
            width: 100%;
            height: auto;
            opacity: 1;
            transition: opacity .2s ease;
        }

        .created {
            position: absolute;
            bottom: 5px;
            left: 10px;
            font-size: 11px;

            i {
                margin-right: 2px;
            }
        }
    }

    .meta {
        padding-right: 50px;
        background-color: #222;
        position: relative;
        padding: 10px 52px 10px 15px;
    }

    .title {
        font-size: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .author {
        font-size: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .icon {
        font-size: 30px;
        top: 10px;
        right: 11px;
        position: absolute;
    }
}
