.profile-list-button {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        margin-bottom: 7px;
    }
}

.profile-button-item {
    display: block;
    position: relative;
    padding-left: 50px;
    background-color: #222;
    opacity: 1;
    transition: opacity .2s ease;
    font-size: 12px;
    height: 40px;

    &:hover {
        opacity: .5;
    }

    .icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        color: white;
        text-align: center;
        padding-top: 9px;
        font-size: 14px;
        background-color: $red;
    }

    .avatar {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .name {
        color: white;
        padding-top: 3px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .profession {
        color: $body-color;
        line-height: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.profile-thumb {
    display: block;
    position: relative;
    background-color: #222;

    &:hover {
        .cover {
            img {
                opacity: .5;
            }
        }
    }

    .cover {
        z-index: 1;
        position: relative;

        img {
            width: 100%;
            height: auto;
            transition: opacity .2s ease;
        }
    }

    .meta {
        padding: 0 20px 20px;
        z-index: 100;
        text-align: center;
        position: relative;

        img {
            border-radius: 1000px;
            margin-top: -25px;
            border: 3px solid #222;
        }
    }

    .name {
        color: white;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .profs {
        color: #999;
        font-size: 11px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
