#page-music_player {
    background-color: #222;
    padding: 35px 0;

    .action {
        font-size: 70px;
        line-height: 60px;
        color: white;
        opacity: 1;
        transition: opacity .2s ease;

        &:hover {
            opacity: .5;
        }

        &.paused {
            .play {
                display: inline;
            }
            .pause {
                display: none;
            }
        }

        &.playing {
            .play {
                display: none;
            }
            .pause {
                display: inline;
            }
        }
    }

    .content {
        background-color: #333;
        padding: 25px 35px;
    }

    .title {
        padding-bottom: 5px;
    }
}
#page-music_player_wavesurf {
    width: 100%;
    height: 84px;
}
.page-music_album-songs {
    > a {
        display: block;
        border-bottom: 1px solid #454545;
        padding: 10px;
        font-size: 12px;
        color: inherit;
        transition: background-color .2s ease;

        &:hover {
            color: inherit;
            background-color: #222;
        }
    }
}
