.form-image {
    border: 1px solid #444;
    padding: .75rem .75rem;
    border-radius: 0.25rem;
    background-color: #222;
    height: 99px;
    font-family: $input-font-family;
    font-weight: $input-font-weight;
    @include font-size($input-font-size);
    position: relative;
    overflow: hidden;

    &.is-invalid {
        border-color: $red;

        &:after {
            content: "";
            position: absolute;
            bottom: .75rem;
            right: .75rem;
            width: 20px;
            height: 20px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f44336'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f44336' stroke='none'/%3e%3c/svg%3e");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .placeholder {
        color: $input-placeholder-color;
        position: absolute;
        top: -3px;
        left: 2px;
    }

    .empty {
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: relative;

        &:hover {
            i {
                opacity: .5;
            }
        }

        i {
            position: absolute;
            left: 50%;
            top: 50%;
            font-size: 72px;
            margin-top: -36px;
            margin-left: -36px;
            opacity: .3;
            transition: opacity .2s ease;
        }
    }

    .preview {
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;

        &:hover {
            .image {
                opacity: .3;
            }
        }

        .image {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            opacity: 1;
            transition: opacity .2s ease;
        }

        .btn-close {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .progress {
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
