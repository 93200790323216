.event-thumb {
    position: relative;
    display: block;
    color: white;
    background-color: #222;
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
        opacity: 1;
        transition: opacity .2s ease;
    }

    &:hover {
        color: white;

        img {
            opacity: .5;
        }

        .event-thumb_info {
            background-color: $red;
        }
    }
}

.event-thumb_info {
    padding: 13px 15px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(34,34,34,.9);
    transition: background-color .2s ease;

    > h3 {
        font-size: 15px;
        margin: 4px 0 0;
        padding: 0;

        line-clamp: 2;
        -webkit-line-clamp: 2;
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }
}

.event-thumb_meta {
    font-size: 11px;
}

.event-thumb_icon {
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 17px;
}

.event-thumb_status {
    font-size: 10px;
    text-transform: uppercase;
    padding: 5px 10px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 700;
}
