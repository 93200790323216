.venue-thumb {
    display: block;
    color: white;
    background-color: #222;
    text-align: center;
    position: relative;

    > img {
        opacity: .5;
        transition: opacity .2s ease;
        width: 100%;
        height: auto;
    }

    &:hover {
        color: white;

        > img {
            opacity: .2;
        }
    }
}
.venue-thumb_info {
    position: absolute;
    top: 50%;
    width: 100%;
    padding: 0 20px;
    margin-top: -34px;

    h3 {
        font-size: 15px;
        padding: 0;
        margin: 0;
        text-shadow: 0 0 4px #222;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.venue-thumb_meta {
    font-size: 11px;
    text-shadow: 0 0 4px #222;
}

.venue-list-item {
    display: block;
    color: inherit;
    text-decoration: none;
    transition: color .2s ease;
    height: 100%;
    position: relative;
    padding: 20px;
    min-height: 75px;

    &:hover {
        .icon {
            .icon-border {
                border-color: #2982c1;
            }
        }
    }

    h2 {
        font-size: 14px;
        margin: 0 0 7px;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 700;
    }

    .address {
        font-size: 12px;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }

    .open-hours {
        font-size: 11px;
    }

    .info {
        z-index: 2;
        position: relative;
        padding-right: 70px;
    }

    .icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        font-size: 11px;
        text-align: center;

        .icon-border {
            border: 1px solid #454545;
            transition: border-color .2s ease;
            border-radius: 1000px;
            height: 40px;
            margin-bottom: 5px;
            font-size: 20px;
            padding-top: 5px;
        }
    }

    .cover {
        background-image: url(var(--image));
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 100%;
        top:0;
        left: 0;
        position: absolute;
        z-index: 1;
        opacity: .1;
    }
}
