#header-embed {
    background-color: #222;
    padding: 10px 0;
}
#header-embed-before {
    background-color: #222;
    height: 25px;
}
#header-embed-after {
    background-color: #222;
    height: 25px;
}

.header-embed-gallery-item {
    position: relative;

    > div {
        text-align: center;
        background-color: #333;
        img {
            width: auto;
            height: 100%;
        }
    }

    a {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        font-size: 12px;
        color: white;
        background-color: rgba(0,0,0,.5);
        padding: 5px 10px;
        opacity: 1;
        transition: opacity .2s ease;

        > i {
            margin-right: 5px;
        }

        &:hover {
            opacity: .5;
        }
    }
}

#header-top {
    color: #777;
    font-size: 11px;
    padding: 7px 0 9px;
    background-color: #111;
    font-family: Lato,sans-serif;

    a {
        color: #777;
        transition: color .3s ease;

        &:hover {
            color: white;
        }
    }
}

#header-top_menu {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    border-left: 1px dotted #777;

    li {
        display: inline-block;
        padding: 0 $grid-gutter-width;
        border-left: 1px dotted #777;
    }
}

#header-logo_img {
    display: block;
    height: 135px;

    img {
        max-width: 100%;
    }
}

#header-logo_friend {
    height: 135px;
    width: 100%;
}

#header-logo {
    background-color: #222;
}

.header-menu {
    background-image: linear-gradient(rgb(51, 51, 51) 1%, rgb(38, 38, 38) 100%);
    position: sticky;
    top: 0px;
    z-index: 5;
}

#header-menu-desktop_items {
    list-style: none;
    padding: 0;
    white-space: nowrap;
    max-width: 1171px;
    margin: 0 auto;
    font-size: 0;

    > li {
        display: inline-block;
        position: relative;

        &:hover{
            > a {
                &:before {
                    height: 100%;
                }
            }
            > ul {
                visibility: visible;
                opacity: 1;
            }
        }

        > a {
            color: white;
            text-transform: uppercase;
            font-size: 16px;
            padding: 10px 15px;
            display: block;
            position: relative;

            &.active,
            &:focus {
                &:before {
                    height: 100%;
                }
            }

            &:focus {
                + ul {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 3px;
                width: 100%;
                transition: height .2s ease;
                z-index: 1;
            }

            span {
                position: relative;
                z-index: 2;
            }
        }

        > ul {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 44px;
            left: 0;
            font-size: 11px;
            background-color: #333;
            list-style: none;
            margin: 0;
            padding: 0;
            border-top: 3px solid #f44336;
            min-width: 182px;
            transition: all .3s ease;
            box-shadow: 0 0 10px 5px rgba(34, 34, 34, .5);

            > li {
                display: block;
                border-bottom: 1px solid #444;

                &:last-child{
                    border-bottom: 0 none;
                }

                > a {
                    padding: 10px 15px;
                    display: block;
                    color: #999;
                    text-transform: uppercase;

                    &:hover {
                        color: #f44336;
                    }
                }
            }
        }

        $colors: (
            0: #33a3f1,
            1: #f58021,
            2: #74b430,
            3: #aa35d6,
            4: #d6ac1b,
            5: #4c60f6,
            6: #32b47b,
            7: #f44336
        );

        @each $name, $color in $colors {
            .c-#{$name}:before {
                background-color: $color;
            }
        }
    }
}

#header-menu-mobile_form {
    padding: 7px 0;

    input {
        border-color: #ccc;
        background-color: #ccc;
        transition: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &:focus {
            background-color: white;
            border-color: white;

            + button {
                background-color: white;
                border-color: white;
            }
        }
    }

    button {
        background-color: #ccc;
        border-color: #ccc;
        border-left-color: transparent;
        transition: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

#header-menu-mobile_toggler {
    padding: 7px 0;
}
