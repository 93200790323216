.gallery-thumb {
    position: relative;
    display: block;
    color: white;
    text-shadow: 0 0 4px #222;

    img {
        width: 100%;
        height: auto;
    }

    &:hover {
        color: white;

        .gallery-thumb_slides {
            opacity: .3;
        }
    }

    .icon {
        position: absolute;
        top: 10px;
        left: 21px;
        font-size: 30px;
    }

    .meta {
        position: absolute;
        top: 15px;
        left: 70px;
        width: calc(100% - 88px);
    }

    .title {
        font-size: 15px;
        padding: 0;
        margin: 0;
    }

    .info {
        font-size: 11px;
        position: absolute;
        right: 15px;
        bottom: 15px;
    }

    .total {
        font-size: 11px;
        position: absolute;
        left: 15px;
        bottom: 15px;
    }
}
.gallery-thumb_slides {
    opacity: 1;
    transition: opacity .2s ease;
}
.gallery-thumb-action {
    width: 100%;
    font-size: 0;
    background-color: #222;

    a {
        display: inline-block;
        padding: 3px 6px;
        text-align: center;
        color: inherit;
        width: 50%;
        font-size: 11px;
        opacity: 1;
        transition: opacity .2s ease;

        &:hover {
            opacity: .5;
        }
    }
}
