#page-photobooth_search {
    margin-bottom: 25px;

    .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0 none;
    }
    .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: white;
        border-left: 0 none;
        border-color: #ced4da;

        &:hover {
            background-color: #333;
        }
    }
}

#page-photobooth_event {
    margin-bottom: 25px;
    background-color: #222;
    padding: 25px 25px 0;

    .cover {
        display: block;
        opacity: 1;
        transition: opacity .2s ease;
        margin-bottom: 25px;

        &:hover {
            opacity: .5;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .name {
        font-size: 20px;
        margin-bottom: 20px;
    }
}
