.photobooth_thumb {
    display: block;

    figure {
        position: relative;
        margin: 0;
        padding: 0;

        img {
            width: 100%;
            height: auto;
            opacity: 1;
            transition: opacity .2s ease;
        }

        i {
            position: absolute;
            top: 10px;
            left: 15px;
            color: white;
        }
    }

    &:hover {
        img {
            opacity: .5;
        }
    }
}

.photobooth_thumb_meta {
    background-color: #222;
    padding: 10px 15px;
    color: $body-color;
    font-size: 13px;

    > div {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
