#footer {
    background-color: #222;
    color: #666;
    font-size: 12px;
    padding-top: 62px;
    padding-bottom: 25px;

    a {
        color: inherit;
        transition: color .2s ease;

        &:hover {
            color: white;
        }
    }

    .footer_title {
        border-bottom: 1px solid #333;
        font-weight: 700;
        padding: 0 0 25px;
        text-transform: uppercase;
        margin: 0 0 25px;
        color: white;
        font-size: 13px;
    }
}

#footer_about-text {
    font-size: 14px;
    margin-bottom: 30px;
}

#footer_about-text_friend {
    padding-top: 30px;
}

#footer_social-items {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
        display: inline-block;
        width: 36px;
        height: 32px;
        text-align: center;
        border: 1px solid #333;
        color: #666;
        font-size: 20px;
        border-radius: 2px;
        transition: color background-color .2s ease;

        &:hover {
            background-color: var(--color);
            border-color: var(--color);
        }
    }
}

#footer_gallery-items {
    a {
        display: block;
        opacity: 1;
        transition: opacity .2s ease;

        &:hover {
            opacity: .7;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}

#footer-bottom {
    background-color: #222;
    color:#666;
    font-size: 12px;
    padding: 30px 0;
    border-top: 1px solid #333;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
            display: inline-block;

            &:not(:first-child):before {
                content: "|";
                margin: 0 10px;
            }

            a {
                color: inherit;
                transition: color .2s ease;

                &:hover {
                    color: white;
                }
            }
        }
    }
}

.footer_category {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 13px;

    a {
        display: block;
        padding: 0 10px 10px;
    }

    li {
        margin-bottom: 10px;

        &:last-child {
            border-bottom: 0 none;
        }
    }
}
