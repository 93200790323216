.section-header {
    border-bottom: 1px solid #454545;
    margin-bottom: 30px;
    position: relative;

    h1,
    h2 {
        color: white;
        font-size: 15px;
        text-transform: uppercase;
        margin: 0 0;
        padding: 30px 0 16px;
        border-bottom: 1px solid #f44336;
        display: inline-block;
    }

    .slider-control {
        position: absolute;
        bottom: 10px;
        right: 0;

        .btn {
            border-color: #555;
            border-radius: 3px;
            color: #999999;
            font-size: 11px;
        }
    }
}

.section-title {
    margin-bottom: 10px;
    border-bottom: 1px solid #454545;
    padding-bottom: 10px;

    h1 {
        color: white;
        font-size: 24px;
        margin: 0 0;
        padding: 30px 0 10px;
    }
}

.section-meta {
    font-size: 11px;

    i {
        margin-right: 5px;
    }

    i:not(:first-child) {
        margin-left: 10px;
    }
}

.section-share {
    padding-bottom: 10px;

    > a {
        display: inline-block;
        margin: 0 2.5px;
        font-size: 20px;
        color: white;
        background-color: var(--color);
        padding: 5px 0;
        text-align: center;
        transition: opacity .2s ease;
        width: 45px;
        border-radius: 3px;

        &:hover {
            opacity: .6;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

.section-cover{
    margin: 0;
    padding: 0;
    margin-bottom: 20px;

    img {
        width: 100%;
        height: auto;
    }
}

.section-content {
    padding: 20px 0 0;
    border-bottom: 1px solid #454545;
    margin-bottom: 10px;

    img {
        max-width: 100%;
    }
}

.section-prev-next {
    margin-top: 25px;
    border-top: 1px solid #454545;
    border-bottom: 1px solid #454545;

    a {
        display: block;
        padding: 25px;
        color: inherit;
        background-color: #333;
        transition: background-color .2s ease;

        &:hover {
            background-color: #222;
        }

        &.first {
            border-right: 1px solid #454545;
        }

        h3 {
            font-size: $font-size-base;
            margin: 0;
            padding: 0;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            overflow: hidden;
            -webkit-box-orient: vertical;
            display: -webkit-box;
        }

        .meta {
            font-size: 11px;
        }
    }
}
