.form-gallery {
    .editor {
        background-color: $input-bg;
        border: $input-border-width solid $input-border-color;
        padding: $input-padding-x $input-padding-x;
        min-height: 41.5px;
        position: relative;

        @include border-radius($input-border-radius, $input-border-radius);

        .form-control {
            background-color: #333;
        }

        .progress {
            height: 2px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}
