.page-post-category_prefix {
    margin-bottom: 25px;
    overflow: auto;
    white-space: nowrap;
    text-align: center;

    .btn {
        min-width: 31px;
    }
}

#page-post-category_header {
    background-color: #222;
    padding: 35px 0;

    + #header-embed-before {
        border-top: 1px solid #333;
    }
}
.page-post-category_header_meta {
    background-color: #333;
    padding: 30px 40px;
    height: 100%;

    .name {
        font-size: 18px;
        font-weight: 700;
        color: white;
        margin-bottom: 10px;
    }
}

#post-category_footer {
    white-space: nowrap;
    overflow: auto;
    background-color: #222;
    border-bottom: 1px solid #333;
    padding: 40px 0;
    text-align: center;
}
.post-category_footer_item {
    display: inline-block;
    margin: 0 15px;

    img {
        height: 100px;
        width: auto;
    }
}
