#marquee {
    border-bottom: 1px dotted #424242;
    padding: 10px 0;
    background-color: #222;
}

#marquee_post {
    .ticker-wrapper.has-js {
        display: block;
        font-size: 13px;
    }
    .ticker {
        width: 100%;
        height: 28px;
        display: block;
        position: relative;
        overflow: hidden;
    }
    .ticker-content {
        margin: 0px;
        padding-top: 6px;
        overflow: hidden;
        white-space: nowrap;

        span {
            color: #f44336;
            margin-right: 5px;
        }

        a {
            color: #ccc;
            font-weight: 700;
            transition: color .2s ease;
            text-decoration: none;

            &:hover {
                color: #f44336;
            }
        }
    }

    .ticker-swipe {
        padding-top: 9px;
        position: absolute;
        top: 0px;
        background-color: #222;
        display: block;
        width: 100%;
        height: 28px;
    }
    .ticker-swipe span {
        display: none;
    }

    .left .ticker-content, .left .ticker-title, .left .ticker {
        float: left;
    }
}

#marquee_label {
    color: white;
    text-transform: uppercase;
    display: inline-block;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        border-radius: 5px;
        background-color: #f44336;
        width: 24px;
        height: 24px;
        transform: rotate(45deg);
        top: 3px;
        right: -9px;
        z-index: 1;
    }

    span {
        background-color: #f44336;
        z-index: 2;
        padding: 7px 14px;
        border-radius: 5px;
        font-size: 11px;
        font-weight: 700;
        position: relative;
        display: block;
        white-space: nowrap;
    }
}

#marquee_search {
    input {
        border-right: 0 none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    button {
        border-left: 0 none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
