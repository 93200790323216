#page-profile_header {
    margin-top: 35px;
    background-color: #222;
    position: relative;

    .btn-edit {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }

    .cover {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        img {
            opacity: 0;
        }
    }

    .avatar {
        position: relative;
        width: 50px;
        height: 50px;

        img {
            position: absolute;
            top: -40px;
            left: 25px;
        }
    }

    .info {
        padding: 25px 25px 30px 145px;
        margin-top: -60px;

        h1 {
            font-size: 20px;
            color: white;
            margin: 0;
            padding: 0;

            a {
                color: inherit;
                transition: opacity .2s ease;

                &:hover {
                    opacity: .5;
                }
            }
        }

        .meta {
            font-size: 12px;

            span {
                padding: 5px;
                white-space: nowrap;

                &:first-child {
                    padding-left: 0;
                }

                i {
                    margin-right: 3px;
                }
            }
        }
    }
}

#page-profile_tab {
    margin: 20px 0;
    overflow: auto;
    white-space: nowrap;
    background-color: #222;
    font-size: 0;
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        font-size: 12px;

        a {
            display: block;
            padding: 0.7rem 1rem;
            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-bottom-color: transparent;
            color: white;
            transition: color .2s ease;

            &:hover {
                color: $red;
            }

            &.active {
                border-bottom-color: $red;
                color: $red;
            }
        }
    }
}
