.page-event_month_day-name_desktop {
    border: 1px solid #454545;
    text-transform: uppercase;
    font-size: 11px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-right-width: 0;

    &:last-child {
        border-right-width: 1px;
    }
}
.page-event_month_day_desktop {
    border: 1px solid #454545;
    font-size: 11px;
    padding-bottom: 5px;
    border-top-width: 0;
    border-right-width: 0;
    min-height: 130px;

    &:last-child {
        border-right-width: 1px;
    }
}
.page-event_month_day_number_desktop {
    display: block;
    color: inherit;
    background-color: #333;
    transition: background-color .2s ease;
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover {
        color: inherit;
        background-color: #222;
    }
}
.page-event_month_day_event_desktop {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;

    a {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: white;
        transition: opacity .2s ease;
        opacity: 1;
        margin: 2px 3px 0;
        background-color: #222;
        padding-right: 5px;

        &:hover {
            color: white;
            opacity: .5;
        }

        > div {
            display: inline-block;
            padding: 2px 5px;
            margin-right: 5px;
        }

        > span {
            padding: 2px 0;
        }
    }
}

.page-event_month_day_number_mobile {
    .date {
        font-size: 1.9rem;
        line-height: 1.6rem;
    }
    .day {
        font-size: 13px;
    }
}

.page-event_month_day_event_mobile {
    a {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: white;
        margin-bottom: 5px;
        background-color: #222;

        .icon {
            display: inline-block;
            padding: 5px 10px;
            margin-right: 5px;
        }

        span {
            padding: 5px 0;
        }
    }
}
